<template>
    <section class="load-total" v-if="status === 'success'">
        <h2 class="sr-only">Данные отчета "Доходы"</h2>
        <div class="load-total__base" v-if="modeId === 'base'">
            <div class="load-total__box box">
                <table class="load-total__table load-total__table_striped">
                    <colgroup>
                        <col class="load-total__table-column load-total__table-column_title">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                    </colgroup>
                    <thead>
                        <tr class="load-total__table-row load-total__table-row_head">
                            <th class="load-total__table-cell load-total__table-cell_head load-total__table-cell_sortable load-total__table-cell_vat"
                                scope="col"
                                rowspan="2"
                                @click="setSorting('common', 'title')"
                            >
                                <span class="load-total__table-value">Источник</span>
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'common' && sorting.field === 'title' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'common' && sorting.field === 'title' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="load-total__table-cell load-total__table-cell_tac load-total__table-cell_head" scope="col" colspan="4">Доставка</th>
                            <th class="load-total__table-cell load-total__table-cell_tac load-total__table-cell_head" scope="col" colspan="4">Зал</th>
                            <th class="load-total__table-cell load-total__table-cell_tac load-total__table-cell_head" scope="col" colspan="4">С собой</th>
                            <th class="load-total__table-cell load-total__table-cell_tac load-total__table-cell_head" scope="col" colspan="4">Самовынос</th>
                            <th class="load-total__table-cell load-total__table-cell_tac load-total__table-cell_head" scope="col" colspan="4">Всего</th>
                        </tr>
                        <tr class="load-total__table-row load-total__table-row_head">
                            <th class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('delivery', 'orders_per_hour_avg')"
                            >
                                <span class="load-total__table-text">Заказов в час</span>
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'delivery' && sorting.field === 'orders_per_hour_avg' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'delivery' && sorting.field === 'orders_per_hour_avg' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('delivery', 'guests_per_hour_avg')"
                            >
                                <span class="load-total__table-text">Гостей в час</span>
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'delivery' && sorting.field === 'guests_per_hour_avg' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'delivery' && sorting.field === 'guests_per_hour_avg' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('delivery', 'dish_per_hour_avg')"
                            >
                                <span class="load-total__table-text">Блюд в час</span>
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'delivery' && sorting.field === 'dish_per_hour_avg' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'delivery' && sorting.field === 'dish_per_hour_avg' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('delivery', 'couriers_per_hour_avg')"
                            >
                                <span class="load-total__table-text">Курьеров в час</span>
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'delivery' && sorting.field === 'couriers_per_hour_avg' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'delivery' && sorting.field === 'couriers_per_hour_avg' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>

                            <th class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('hall', 'orders_per_hour_avg')"
                            >
                                <span class="load-total__table-text">Заказов в час</span>
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'hall' && sorting.field === 'orders_per_hour_avg' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'hall' && sorting.field === 'orders_per_hour_avg' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('hall', 'guests_per_hour_avg')"
                            >
                                <span class="load-total__table-text">Гостей в час</span>
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'hall' && sorting.field === 'guests_per_hour_avg' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'hall' && sorting.field === 'guests_per_hour_avg' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('hall', 'dish_per_hour_avg')"
                            >
                                <span class="load-total__table-text">Блюд в час</span>
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'hall' && sorting.field === 'dish_per_hour_avg' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'hall' && sorting.field === 'dish_per_hour_avg' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('hall', 'couriers_per_hour_avg')"
                            >
                                <span class="load-total__table-text">Курьеров в час</span>
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'hall' && sorting.field === 'couriers_per_hour_avg' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'hall' && sorting.field === 'couriers_per_hour_avg' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>

                            <th class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('take', 'orders_per_hour_avg')"
                            >
                                <span class="load-total__table-text">Заказов в час</span>
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'take' && sorting.field === 'orders_per_hour_avg' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'take' && sorting.field === 'orders_per_hour_avg' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('take', 'guests_per_hour_avg')"
                            >
                                <span class="load-total__table-text">Гостей в час</span>
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'take' && sorting.field === 'guests_per_hour_avg' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'take' && sorting.field === 'guests_per_hour_avg' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('take', 'dish_per_hour_avg')"
                            >
                                <span class="load-total__table-text">Блюд в час</span>
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'take' && sorting.field === 'dish_per_hour_avg' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'take' && sorting.field === 'dish_per_hour_avg' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('take', 'couriers_per_hour_avg')"
                            >
                                <span class="load-total__table-text">Курьеров в час</span>
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'take' && sorting.field === 'couriers_per_hour_avg' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'take' && sorting.field === 'couriers_per_hour_avg' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>

                            <th class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('pickup', 'orders_per_hour_avg')"
                            >
                                <span class="load-total__table-text">Заказов в час</span>
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'pickup' && sorting.field === 'orders_per_hour_avg' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'pickup' && sorting.field === 'orders_per_hour_avg' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('pickup', 'guests_per_hour_avg')"
                            >
                                <span class="load-total__table-text">Гостей в час</span>
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'pickup' && sorting.field === 'guests_per_hour_avg' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'pickup' && sorting.field === 'guests_per_hour_avg' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('pickup', 'dish_per_hour_avg')"
                            >
                                <span class="load-total__table-text">Блюд в час</span>
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'pickup' && sorting.field === 'dish_per_hour_avg' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'pickup' && sorting.field === 'dish_per_hour_avg' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('pickup', 'couriers_per_hour_avg')"
                            >
                                <span class="load-total__table-text">Курьеров в час</span>
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'pickup' && sorting.field === 'couriers_per_hour_avg' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'pickup' && sorting.field === 'couriers_per_hour_avg' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>

                            <th class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('total', 'orders_per_hour_avg')"
                            >
                                <span class="load-total__table-text">Заказов в час</span>
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'total' && sorting.field === 'orders_per_hour_avg' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'total' && sorting.field === 'orders_per_hour_avg' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('total', 'guests_per_hour_avg')"
                            >
                                <span class="load-total__table-text">Гостей в час</span>
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'total' && sorting.field === 'guests_per_hour_avg' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'total' && sorting.field === 'guests_per_hour_avg' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('total', 'dish_per_hour_avg')"
                            >
                                <span class="load-total__table-text">Блюд в час</span>
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'total' && sorting.field === 'dish_per_hour_avg' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'total' && sorting.field === 'dish_per_hour_avg' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_sortable"
                                scope="col"
                                @click="setSorting('total', 'couriers_per_hour_avg')"
                            >
                                <span class="load-total__table-text">Курьеров в час</span>
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'total' && sorting.field === 'couriers_per_hour_avg' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="load-total__table-icon"
                                    v-if="sorting.group === 'total' && sorting.field === 'couriers_per_hour_avg' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="load-total__table-row load-total__table-row_body load-total__table-row_pinned">
                            <td class="load-total__table-cell load-total__table-cell_text-medium load-total__table-cell_body load-total__table-cell_title">
                                <router-link class="link link_red"
                                    :to="{ name: 'LoadRestaurant', params: { id: 'total' } }"
                                    title="Смотреть общую эффективность по всем ресторанам"
                                >
                                    Итого
                                </router-link>
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportLoad.total.delivery.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportLoad.total.delivery.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportLoad.total.delivery.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <template v-if="reportLoad.total.delivery.hasOwnProperty('couriers_per_hour_avg')">
                                    {{reportLoad.total.delivery.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет<br> данных</template>
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportLoad.total.hall.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportLoad.total.hall.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportLoad.total.hall.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <template v-if="reportLoad.total.hall.hasOwnProperty('couriers_per_hour_avg')">
                                    {{reportLoad.total.hall.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет<br> данных</template>
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportLoad.total.take.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportLoad.total.take.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportLoad.total.take.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <template v-if="reportLoad.total.take.hasOwnProperty('couriers_per_hour_avg')">
                                    {{reportLoad.total.take.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет<br> данных</template>
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportLoad.total.pickup.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportLoad.total.pickup.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportLoad.total.pickup.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <template v-if="reportLoad.total.pickup.hasOwnProperty('couriers_per_hour_avg')">
                                    {{reportLoad.total.pickup.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет<br> данных</template>
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportLoad.total.total.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportLoad.total.total.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportLoad.total.total.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <template v-if="reportLoad.total.total.hasOwnProperty('couriers_per_hour_avg')">
                                    {{reportLoad.total.total.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет<br> данных</template>
                            </td>
                        </tr>
                        <tr class="load-total__table-row load-total__table-row_body load-total__table-row_pinned"
                            v-if="reportAverage"
                        >
                            <td class="load-total__table-cell load-total__table-cell_text-medium load-total__table-cell_body load-total__table-cell_title">Среднее</td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportAverage.delivery.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportAverage.delivery.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportAverage.delivery.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <template v-if="reportAverage.delivery.hasOwnProperty('couriers_per_hour_avg')">
                                    {{reportAverage.delivery.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет<br> данных</template>
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportAverage.hall.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportAverage.hall.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportAverage.hall.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <template v-if="reportAverage.hall.hasOwnProperty('couriers_per_hour_avg')">
                                    {{reportAverage.hall.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет<br> данных</template>
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportAverage.take.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportAverage.take.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportAverage.take.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <template v-if="reportAverage.take.hasOwnProperty('couriers_per_hour_avg')">
                                    {{reportAverage.take.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет<br> данных</template>
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportAverage.pickup.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportAverage.pickup.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportAverage.pickup.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <template v-if="reportAverage.pickup.hasOwnProperty('couriers_per_hour_avg')">
                                    {{reportAverage.pickup.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет<br> данных</template>
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportAverage.total.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportAverage.total.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                {{reportAverage.total.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <template v-if="reportAverage.total.hasOwnProperty('couriers_per_hour_avg')">
                                    {{reportAverage.total.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет<br> данных</template>
                            </td>
                        </tr>
                        <tr class="load-total__table-row load-total__table-row_body"
                            v-for="restaurant in reportRestaurants" :key="restaurant.id"
                        >
                            <th class="load-total__table-cell load-total__table-cell_text-medium load-total__table-cell_body" scope="row">
                                <router-link class="link link_red"
                                    :to="{ name: 'LoadRestaurant', params: { id: restaurant.id } }"
                                    :title="`Смотреть доход по ресторану ${restaurant.title}`">
                                    {{restaurant.title}}
                                </router-link>
                            </th>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <span class="load-total__table-value">
                                    {{restaurant.delivery.orders_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="reportAverage && reportAverage.delivery.orders_per_hour_avg !== 0"
                                    :primaryValue="restaurant.delivery.orders_per_hour_avg"
                                    :compareValue="reportAverage.delivery.orders_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <span class="load-total__table-value">
                                    {{restaurant.delivery.guests_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="reportAverage && reportAverage.delivery.guests_per_hour_avg !== 0"
                                    :primaryValue="restaurant.delivery.guests_per_hour_avg"
                                    :compareValue="reportAverage.delivery.guests_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <span class="load-total__table-value">
                                    {{restaurant.delivery.dish_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="reportAverage && reportAverage.delivery.dish_per_hour_avg !== 0"
                                    :primaryValue="restaurant.delivery.dish_per_hour_avg"
                                    :compareValue="reportAverage.delivery.dish_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <template v-if="restaurant.delivery.hasOwnProperty('couriers_per_hour_avg')">
                                    <span class="load-total__table-value">
                                        {{restaurant.delivery.couriers_per_hour_avg | floatFormat}}
                                    </span>
                                    <difference class="load-total__table-value"
                                        v-if="reportAverage && reportAverage.delivery.couriers_per_hour_avg !== 0"
                                        :primaryValue="restaurant.delivery.couriers_per_hour_avg"
                                        :compareValue="reportAverage.delivery.couriers_per_hour_avg"
                                        mode="percentage"
                                        filter="percentFloatFormat"
                                    />
                                </template>
                                <span v-else class="load-total__table-value">нет<br> данных</span>
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <span class="load-total__table-value">
                                    {{restaurant.hall.orders_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="reportAverage && reportAverage.hall.orders_per_hour_avg !== 0"
                                    :primaryValue="restaurant.hall.orders_per_hour_avg"
                                    :compareValue="reportAverage.hall.orders_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <span class="load-total__table-value">
                                    {{restaurant.hall.guests_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="reportAverage && reportAverage.hall.guests_per_hour_avg !== 0"
                                    :primaryValue="restaurant.hall.guests_per_hour_avg"
                                    :compareValue="reportAverage.hall.guests_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <span class="load-total__table-value">
                                    {{restaurant.hall.dish_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="reportAverage && reportAverage.hall.dish_per_hour_avg !== 0"
                                    :primaryValue="restaurant.hall.dish_per_hour_avg"
                                    :compareValue="reportAverage.hall.dish_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <template v-if="restaurant.hall.hasOwnProperty('couriers_per_hour_avg')">
                                    <span class="load-total__table-value">
                                        {{restaurant.hall.couriers_per_hour_avg | floatFormat}}
                                    </span>
                                    <difference class="load-total__table-value"
                                        v-if="reportAverage && reportAverage.hall.couriers_per_hour_avg !== 0"
                                        :primaryValue="restaurant.hall.couriers_per_hour_avg"
                                        :compareValue="reportAverage.hall.couriers_per_hour_avg"
                                        mode="percentage"
                                        filter="percentFloatFormat"
                                    />
                                </template>
                                <span v-else class="load-total__table-value">нет<br> данных</span>
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <span class="load-total__table-value">
                                    {{restaurant.take.orders_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="reportAverage && reportAverage.take.orders_per_hour_avg !== 0"
                                    :primaryValue="restaurant.take.orders_per_hour_avg"
                                    :compareValue="reportAverage.take.orders_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <span class="load-total__table-value">
                                    {{restaurant.take.guests_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="reportAverage && reportAverage.take.guests_per_hour_avg !== 0"
                                    :primaryValue="restaurant.take.guests_per_hour_avg"
                                    :compareValue="reportAverage.take.guests_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <span class="load-total__table-value">
                                    {{restaurant.take.dish_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="reportAverage && reportAverage.take.dish_per_hour_avg !== 0"
                                    :primaryValue="restaurant.take.dish_per_hour_avg"
                                    :compareValue="reportAverage.take.dish_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <template v-if="restaurant.take.hasOwnProperty('couriers_per_hour_avg')">
                                    <span class="load-total__table-value">
                                        {{restaurant.take.couriers_per_hour_avg | floatFormat}}
                                    </span>
                                    <difference class="load-total__table-value"
                                        v-if="reportAverage && reportAverage.take.couriers_per_hour_avg !== 0"
                                        :primaryValue="restaurant.take.couriers_per_hour_avg"
                                        :compareValue="reportAverage.take.couriers_per_hour_avg"
                                        mode="percentage"
                                        filter="percentFloatFormat"
                                    />
                                </template>
                                <span v-else class="load-total__table-value">нет<br> данных</span>
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <span class="load-total__table-value">
                                    {{restaurant.pickup.orders_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="reportAverage && reportAverage.pickup.orders_per_hour_avg !== 0"
                                    :primaryValue="restaurant.pickup.orders_per_hour_avg"
                                    :compareValue="reportAverage.pickup.orders_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <span class="load-total__table-value">
                                    {{restaurant.pickup.guests_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="reportAverage && reportAverage.pickup.guests_per_hour_avg !== 0"
                                    :primaryValue="restaurant.pickup.guests_per_hour_avg"
                                    :compareValue="reportAverage.pickup.guests_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <span class="load-total__table-value">
                                    {{restaurant.pickup.dish_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="reportAverage && reportAverage.pickup.dish_per_hour_avg !== 0"
                                    :primaryValue="restaurant.pickup.dish_per_hour_avg"
                                    :compareValue="reportAverage.pickup.dish_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <template v-if="restaurant.pickup.hasOwnProperty('couriers_per_hour_avg')">
                                    <span class="load-total__table-value">
                                        {{restaurant.pickup.couriers_per_hour_avg | floatFormat}}
                                    </span>
                                    <difference class="load-total__table-value"
                                        v-if="reportAverage && reportAverage.pickup.couriers_per_hour_avg !== 0"
                                        :primaryValue="restaurant.pickup.couriers_per_hour_avg"
                                        :compareValue="reportAverage.pickup.couriers_per_hour_avg"
                                        mode="percentage"
                                        filter="percentFloatFormat"
                                    />
                                </template>
                                <span v-else class="load-total__table-value">нет<br> данных</span>
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <span class="load-total__table-value">
                                    {{restaurant.total.orders_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="reportAverage && reportAverage.total.orders_per_hour_avg !== 0"
                                    :primaryValue="restaurant.total.orders_per_hour_avg"
                                    :compareValue="reportAverage.total.orders_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <span class="load-total__table-value">
                                    {{restaurant.total.guests_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="reportAverage && reportAverage.total.guests_per_hour_avg !== 0"
                                    :primaryValue="restaurant.total.guests_per_hour_avg"
                                    :compareValue="reportAverage.total.guests_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <span class="load-total__table-value">
                                    {{restaurant.total.dish_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="reportAverage && reportAverage.total.dish_per_hour_avg !== 0"
                                    :primaryValue="restaurant.total.dish_per_hour_avg "
                                    :compareValue="reportAverage.total.dish_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_tar load-total__table-cell_body">
                                <template v-if="restaurant.total.hasOwnProperty('couriers_per_hour_avg')">
                                    <span class="load-total__table-value">
                                        {{restaurant.total.couriers_per_hour_avg | floatFormat}}
                                    </span>
                                    <difference class="load-total__table-value"
                                        v-if="reportAverage && reportAverage.total.couriers_per_hour_avg !== 0"
                                        :primaryValue="restaurant.total.couriers_per_hour_avg"
                                        :compareValue="reportAverage.total.couriers_per_hour_avg"
                                        mode="percentage"
                                        filter="percentFloatFormat"
                                    />
                                </template>
                                <span v-else class="load-total__table-value">нет<br> данных</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="load-total__compare-other-period" v-else-if="modeId === 'compareOtherPeriod' && compareLoad">
            <div class="load-total__box box">
                <table class="load-total__table">
                    <caption class="load-total__table-caption">
                        <router-link class="link link_red"
                            :to="{ name: 'LoadRestaurant', params: { id: 'total' } }"
                            title="Смотреть общую эффективность по всем ресторанам"
                        >
                            Итого
                        </router-link>
                    </caption>
                    <colgroup>
                        <col class="load-total__table-column load-total__table-column_title">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                    </colgroup>
                    <thead>
                        <tr class="load-total__table-row load-total__table-row_head">
                            <th class="load-total__table-cell load-total__table-cell_vat load-total__table-cell_head" scope="col" rowspan="2">Источник</th>
                            <th class="load-total__table-cell load-total__table-cell_head load-total__table-cell_tac" scope="col" colspan="4">Доставка</th>
                            <th class="load-total__table-cell load-total__table-cell_head load-total__table-cell_tac" scope="col" colspan="4">Зал</th>
                            <th class="load-total__table-cell load-total__table-cell_head load-total__table-cell_tac" scope="col" colspan="4">С собой</th>
                            <th class="load-total__table-cell load-total__table-cell_head load-total__table-cell_tac" scope="col" colspan="4">Самовынос</th>
                            <th class="load-total__table-cell load-total__table-cell_head load-total__table-cell_tac" scope="col" colspan="4">Всего</th>
                        </tr>
                        <tr class="load-total__table-row load-total__table-row_head">
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Заказов в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Гостей в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Блюд в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Курьеров в час</th>

                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Заказов в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Гостей в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Блюд в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Курьеров в час</th>

                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Заказов в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Гостей в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Блюд в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Курьеров в час</th>

                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Заказов в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Гостей в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Блюд в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Курьеров в час</th>

                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Заказов в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Гостей в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Блюд в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Курьеров в час</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="load-total__table-row load-total__table-row_body">
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_title">
                                {{reportDaterange | daterangeText}}
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{reportLoad.total.delivery.orders_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="compareLoad.total.delivery.orders_per_hour_avg !== 0"
                                    :primaryValue="reportLoad.total.delivery.orders_per_hour_avg"
                                    :compareValue="compareLoad.total.delivery.orders_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{reportLoad.total.delivery.guests_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="compareLoad.total.delivery.guests_per_hour_avg !== 0"
                                    :primaryValue="reportLoad.total.delivery.guests_per_hour_avg"
                                    :compareValue="compareLoad.total.delivery.guests_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{reportLoad.total.delivery.dish_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="compareLoad.total.delivery.dish_per_hour_avg !== 0"
                                    :primaryValue="reportLoad.total.delivery.dish_per_hour_avg"
                                    :compareValue="compareLoad.total.delivery.dish_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <template v-if="reportLoad.total.delivery.hasOwnProperty('couriers_per_hour_avg')">
                                    <span class="load-total__table-value">
                                        {{reportLoad.total.delivery.couriers_per_hour_avg | floatFormat}}
                                    </span>
                                    <difference class="load-total__table-value"
                                        v-if="compareLoad.total.delivery.couriers_per_hour_avg !== 0"
                                        :primaryValue="reportLoad.total.delivery.couriers_per_hour_avg"
                                        :compareValue="compareLoad.total.delivery.couriers_per_hour_avg"
                                        mode="percentage"
                                        filter="percentFloatFormat"
                                    />
                                </template>
                                <span v-else class="load-total__table-value">нет<br> данных</span>
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{reportLoad.total.hall.orders_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="compareLoad.total.hall.orders_per_hour_avg !== 0"
                                    :primaryValue="reportLoad.total.hall.orders_per_hour_avg"
                                    :compareValue="compareLoad.total.hall.orders_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{reportLoad.total.hall.guests_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="compareLoad.total.hall.guests_per_hour_avg !== 0"
                                    :primaryValue="reportLoad.total.hall.guests_per_hour_avg"
                                    :compareValue="compareLoad.total.hall.guests_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{reportLoad.total.hall.dish_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="compareLoad.total.hall.dish_per_hour_avg !== 0"
                                    :primaryValue="reportLoad.total.hall.dish_per_hour_avg"
                                    :compareValue="compareLoad.total.hall.dish_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <template v-if="reportLoad.total.hall.hasOwnProperty('couriers_per_hour_avg')">
                                    <span class="load-total__table-value">
                                        {{reportLoad.total.hall.couriers_per_hour_avg | floatFormat}}
                                    </span>
                                    <difference class="load-total__table-value"
                                        v-if="compareLoad.total.hall.couriers_per_hour_avg !== 0"
                                        :primaryValue="reportLoad.total.hall.couriers_per_hour_avg"
                                        :compareValue="compareLoad.total.hall.couriers_per_hour_avg"
                                        mode="percentage"
                                        filter="percentFloatFormat"
                                    />
                                </template>
                                <span v-else class="load-total__table-value">нет<br> данных</span>
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{reportLoad.total.take.orders_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="compareLoad.total.take.orders_per_hour_avg !== 0"
                                    :primaryValue="reportLoad.total.take.orders_per_hour_avg"
                                    :compareValue="compareLoad.total.take.orders_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{reportLoad.total.take.guests_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="compareLoad.total.take.guests_per_hour_avg !== 0"
                                    :primaryValue="reportLoad.total.take.guests_per_hour_avg"
                                    :compareValue="compareLoad.total.take.guests_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{reportLoad.total.take.dish_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="compareLoad.total.take.dish_per_hour_avg !== 0"
                                    :primaryValue="reportLoad.total.take.dish_per_hour_avg"
                                    :compareValue="compareLoad.total.take.dish_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <template v-if="reportLoad.total.take.hasOwnProperty('couriers_per_hour_avg')">
                                    <span class="load-total__table-value">
                                        {{reportLoad.total.take.couriers_per_hour_avg | floatFormat}}
                                    </span>
                                    <difference class="load-total__table-value"
                                        v-if="compareLoad.total.take.couriers_per_hour_avg !== 0"
                                        :primaryValue="reportLoad.total.take.couriers_per_hour_avg"
                                        :compareValue="compareLoad.total.take.couriers_per_hour_avg"
                                        mode="percentage"
                                        filter="percentFloatFormat"
                                    />
                                </template>
                                <span v-else class="load-total__table-value">нет<br> данных</span>
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{reportLoad.total.pickup.orders_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="compareLoad.total.pickup.orders_per_hour_avg !== 0"
                                    :primaryValue="reportLoad.total.pickup.orders_per_hour_avg"
                                    :compareValue="compareLoad.total.pickup.orders_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{reportLoad.total.pickup.guests_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="compareLoad.total.pickup.guests_per_hour_avg !== 0"
                                    :primaryValue="reportLoad.total.pickup.guests_per_hour_avg"
                                    :compareValue="compareLoad.total.pickup.guests_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{reportLoad.total.pickup.dish_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="compareLoad.total.pickup.dish_per_hour_avg !== 0"
                                    :primaryValue="reportLoad.total.pickup.dish_per_hour_avg"
                                    :compareValue="compareLoad.total.pickup.dish_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <template v-if="reportLoad.total.pickup.hasOwnProperty('couriers_per_hour_avg')">
                                    <span class="load-total__table-value">
                                        {{reportLoad.total.pickup.couriers_per_hour_avg | floatFormat}}
                                    </span>
                                    <difference class="load-total__table-value"
                                        v-if="compareLoad.total.pickup.couriers_per_hour_avg !== 0"
                                        :primaryValue="reportLoad.total.pickup.couriers_per_hour_avg"
                                        :compareValue="compareLoad.total.pickup.couriers_per_hour_avg"
                                        mode="percentage"
                                        filter="percentFloatFormat"
                                    />
                                </template>
                                <span v-else class="load-total__table-value">нет<br> данных</span>
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{reportLoad.total.total.orders_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="compareLoad.total.total.orders_per_hour_avg !== 0"
                                    :primaryValue="reportLoad.total.total.orders_per_hour_avg"
                                    :compareValue="compareLoad.total.total.orders_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{reportLoad.total.total.guests_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="compareLoad.total.total.guests_per_hour_avg !== 0"
                                    :primaryValue="reportLoad.total.total.guests_per_hour_avg"
                                    :compareValue="compareLoad.total.total.guests_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{reportLoad.total.total.dish_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="compareLoad.total.total.dish_per_hour_avg !== 0"
                                    :primaryValue="reportLoad.total.total.dish_per_hour_avg"
                                    :compareValue="compareLoad.total.total.dish_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <template v-if="reportLoad.total.pickup.hasOwnProperty('couriers_per_hour_avg')">
                                    <span class="load-total__table-value">
                                        {{reportLoad.total.total.couriers_per_hour_avg | floatFormat}}
                                    </span>
                                    <difference class="load-total__table-value"
                                        v-if="compareLoad.total.total.couriers_per_hour_avg !== 0"
                                        :primaryValue="reportLoad.total.total.couriers_per_hour_avg"
                                        :compareValue="compareLoad.total.total.couriers_per_hour_avg"
                                        mode="percentage"
                                        filter="percentFloatFormat"
                                    />
                                </template>
                                <span v-else class="load-total__table-value">нет<br> данных</span>
                            </td>
                        </tr>
                        <tr class="load-total__table-row load-total__table-row_body">
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_title">
                                {{compareDaterange | daterangeText}}
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{compareLoad.total.delivery.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{compareLoad.total.delivery.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{compareLoad.total.delivery.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <template v-if="compareLoad.total.delivery.hasOwnProperty('couriers_per_hour_avg')">
                                    {{compareLoad.total.delivery.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет<br> данных</template>
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{compareLoad.total.hall.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{compareLoad.total.hall.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{compareLoad.total.hall.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <template v-if="compareLoad.total.hall.hasOwnProperty('couriers_per_hour_avg')">
                                    {{compareLoad.total.hall.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет<br> данных</template>
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{compareLoad.total.take.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{compareLoad.total.take.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{compareLoad.total.take.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <template v-if="compareLoad.total.take.hasOwnProperty('couriers_per_hour_avg')">
                                    {{compareLoad.total.take.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет<br> данных</template>
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{compareLoad.total.pickup.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{compareLoad.total.pickup.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{compareLoad.total.pickup.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <template v-if="compareLoad.total.pickup.hasOwnProperty('couriers_per_hour_avg')">
                                    {{compareLoad.total.pickup.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет<br> данных</template>
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{compareLoad.total.total.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{compareLoad.total.total.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{compareLoad.total.total.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <template v-if="compareLoad.total.total.hasOwnProperty('couriers_per_hour_avg')">
                                    {{compareLoad.total.total.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет<br> данных</template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="load-total__box box"
                v-for="restaurant in reportLoad.restaurants" :key="restaurant.id"
            >
                <table class="load-total__table">
                    <caption class="load-total__table-caption">
                        <router-link class="link link_red"
                            :to="{ name: 'LoadRestaurant', params: { id: restaurant.id } }"
                            :title="`Смотреть эффективность ресторана ${restaurant.title}`">
                            {{restaurant.title}}
                        </router-link>
                    </caption>
                    <colgroup>
                        <col class="load-total__table-column load-total__table-column_title">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                        <col class="load-total__table-column load-total__table-column_average">
                    </colgroup>
                    <thead>
                        <tr class="load-total__table-row load-total__table-row_head">
                            <th class="load-total__table-cell load-total__table-cell_vat load-total__table-cell_head" scope="col" rowspan="2">Источник</th>
                            <th class="load-total__table-cell load-total__table-cell_head load-total__table-cell_tac" scope="col" colspan="4">Доставка</th>
                            <th class="load-total__table-cell load-total__table-cell_head load-total__table-cell_tac" scope="col" colspan="4">Зал</th>
                            <th class="load-total__table-cell load-total__table-cell_head load-total__table-cell_tac" scope="col" colspan="4">С собой</th>
                            <th class="load-total__table-cell load-total__table-cell_head load-total__table-cell_tac" scope="col" colspan="4">Самовынос</th>
                            <th class="load-total__table-cell load-total__table-cell_head load-total__table-cell_tac" scope="col" colspan="4">Всего</th>
                        </tr>
                        <tr class="load-total__table-row load-total__table-row_head">
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Заказов в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Гостей в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Блюд в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Курьеров в час</th>

                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Заказов в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Гостей в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Блюд в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Курьеров в час</th>

                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Заказов в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Гостей в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Блюд в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Курьеров в час</th>

                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Заказов в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Гостей в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Блюд в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Курьеров в час</th>

                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Заказов в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Гостей в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Блюд в час</th>
                            <th class="load-total__table-cell load-total__table-cell_text-small load-total__table-cell_head load-total__table-cell_tar" scope="col">Курьеров в час</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="load-total__table-row load-total__table-row_body">
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_title">
                                {{reportDaterange | daterangeText}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{restaurant.delivery.orders_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="findRestaurant(compareLoad, restaurant.id) && findRestaurant(compareLoad, restaurant.id).delivery.orders_per_hour_avg !== 0"
                                    :primaryValue="restaurant.delivery.orders_per_hour_avg"
                                    :compareValue="findRestaurant(compareLoad, restaurant.id).delivery.orders_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{restaurant.delivery.guests_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="findRestaurant(compareLoad, restaurant.id) && findRestaurant(compareLoad, restaurant.id).delivery.guests_per_hour_avg !== 0"
                                    :primaryValue="restaurant.delivery.guests_per_hour_avg"
                                    :compareValue="findRestaurant(compareLoad, restaurant.id).delivery.guests_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{restaurant.delivery.dish_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="findRestaurant(compareLoad, restaurant.id) && findRestaurant(compareLoad, restaurant.id).delivery.dish_per_hour_avg !== 0"
                                    :primaryValue="restaurant.delivery.dish_per_hour_avg"
                                    :compareValue="findRestaurant(compareLoad, restaurant.id).delivery.dish_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <template v-if="restaurant.delivery.hasOwnProperty('couriers_per_hour_avg')">
                                    <span class="load-total__table-value">
                                        {{restaurant.delivery.couriers_per_hour_avg | floatFormat}}
                                    </span>
                                    <difference class="load-total__table-value"
                                        v-if="findRestaurant(compareLoad, restaurant.id) && findRestaurant(compareLoad, restaurant.id).delivery.hasOwnProperty('couriers_per_hour_avg') && findRestaurant(compareLoad, restaurant.id).delivery.couriers_per_hour_avg !== 0"
                                        :primaryValue="restaurant.delivery.couriers_per_hour_avg"
                                        :compareValue="findRestaurant(compareLoad, restaurant.id).delivery.couriers_per_hour_avg"
                                        mode="percentage"
                                        filter="percentFloatFormat"
                                    />
                                </template>
                                <span v-else class="load-total__table-value">нет<br> данных</span>
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{restaurant.hall.orders_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="findRestaurant(compareLoad, restaurant.id) && findRestaurant(compareLoad, restaurant.id).hall.orders_per_hour_avg !== 0"
                                    :primaryValue="restaurant.hall.orders_per_hour_avg"
                                    :compareValue="findRestaurant(compareLoad, restaurant.id).hall.orders_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{restaurant.hall.guests_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="findRestaurant(compareLoad, restaurant.id) && findRestaurant(compareLoad, restaurant.id).hall.guests_per_hour_avg !== 0"
                                    :primaryValue="restaurant.hall.guests_per_hour_avg"
                                    :compareValue="findRestaurant(compareLoad, restaurant.id).hall.guests_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{restaurant.hall.dish_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="findRestaurant(compareLoad, restaurant.id) && findRestaurant(compareLoad, restaurant.id).hall.dish_per_hour_avg !== 0"
                                    :primaryValue="restaurant.hall.dish_per_hour_avg"
                                    :compareValue="findRestaurant(compareLoad, restaurant.id).hall.dish_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <template v-if="restaurant.hall.hasOwnProperty('couriers_per_hour_avg')">
                                    <span class="load-total__table-value">
                                        {{restaurant.hall.couriers_per_hour_avg | floatFormat}}
                                    </span>
                                    <difference class="load-total__table-value"
                                        v-if="findRestaurant(compareLoad, restaurant.id) && findRestaurant(compareLoad, restaurant.id).hall.hasOwnProperty('couriers_per_hour_avg') && findRestaurant(compareLoad, restaurant.id).hall.couriers_per_hour_avg !== 0"
                                        :primaryValue="restaurant.hall.couriers_per_hour_avg"
                                        :compareValue="findRestaurant(compareLoad, restaurant.id).hall.couriers_per_hour_avg"
                                        mode="percentage"
                                        filter="percentFloatFormat"
                                    />
                                </template>
                                <span v-else class="load-total__table-value">нет<br> данных</span>
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{restaurant.take.orders_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="findRestaurant(compareLoad, restaurant.id) && findRestaurant(compareLoad, restaurant.id).take.orders_per_hour_avg !== 0"
                                    :primaryValue="restaurant.take.orders_per_hour_avg"
                                    :compareValue="findRestaurant(compareLoad, restaurant.id).take.orders_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{restaurant.take.guests_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="findRestaurant(compareLoad, restaurant.id) && findRestaurant(compareLoad, restaurant.id).take.guests_per_hour_avg !== 0"
                                    :primaryValue="restaurant.take.guests_per_hour_avg"
                                    :compareValue="findRestaurant(compareLoad, restaurant.id).take.guests_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{restaurant.take.dish_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="findRestaurant(compareLoad, restaurant.id) && findRestaurant(compareLoad, restaurant.id).take.dish_per_hour_avg !== 0"
                                    :primaryValue="restaurant.take.dish_per_hour_avg"
                                    :compareValue="findRestaurant(compareLoad, restaurant.id).take.dish_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <template v-if="restaurant.take.hasOwnProperty('couriers_per_hour_avg')">
                                    <span class="load-total__table-value">
                                        {{restaurant.take.couriers_per_hour_avg | floatFormat}}
                                    </span>
                                    <difference class="load-total__table-value"
                                        v-if="findRestaurant(compareLoad, restaurant.id) && findRestaurant(compareLoad, restaurant.id).take.hasOwnProperty('couriers_per_hour_avg') && findRestaurant(compareLoad, restaurant.id).take.couriers_per_hour_avg !== 0"
                                        :primaryValue="restaurant.take.couriers_per_hour_avg"
                                        :compareValue="findRestaurant(compareLoad, restaurant.id).take.couriers_per_hour_avg"
                                        mode="percentage"
                                        filter="percentFloatFormat"
                                    />
                                </template>
                                <span v-else class="load-total__table-value">нет<br> данных</span>
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{restaurant.pickup.orders_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="findRestaurant(compareLoad, restaurant.id) && findRestaurant(compareLoad, restaurant.id).pickup.orders_per_hour_avg !== 0"
                                    :primaryValue="restaurant.pickup.orders_per_hour_avg"
                                    :compareValue="findRestaurant(compareLoad, restaurant.id).pickup.orders_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{restaurant.pickup.guests_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="findRestaurant(compareLoad, restaurant.id) && findRestaurant(compareLoad, restaurant.id).pickup.guests_per_hour_avg !== 0"
                                    :primaryValue="restaurant.pickup.guests_per_hour_avg"
                                    :compareValue="findRestaurant(compareLoad, restaurant.id).pickup.guests_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{restaurant.pickup.dish_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="findRestaurant(compareLoad, restaurant.id) && findRestaurant(compareLoad, restaurant.id).pickup.dish_per_hour_avg !== 0"
                                    :primaryValue="restaurant.pickup.dish_per_hour_avg"
                                    :compareValue="findRestaurant(compareLoad, restaurant.id).pickup.dish_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <template v-if="restaurant.pickup.hasOwnProperty('couriers_per_hour_avg')">
                                    <span class="load-total__table-value">
                                        {{restaurant.pickup.couriers_per_hour_avg | floatFormat}}
                                    </span>
                                    <difference class="load-total__table-value"
                                        v-if="findRestaurant(compareLoad, restaurant.id) && findRestaurant(compareLoad, restaurant.id).pickup.hasOwnProperty('couriers_per_hour_avg') && findRestaurant(compareLoad, restaurant.id).pickup.couriers_per_hour_avg !== 0"
                                        :primaryValue="restaurant.pickup.couriers_per_hour_avg"
                                        :compareValue="findRestaurant(compareLoad, restaurant.id).pickup.couriers_per_hour_avg"
                                        mode="percentage"
                                        filter="percentFloatFormat"
                                    />
                                </template>
                                <span v-else class="load-total__table-value">нет<br> данных</span>
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{restaurant.total.orders_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="findRestaurant(compareLoad, restaurant.id) && findRestaurant(compareLoad, restaurant.id).total.orders_per_hour_avg !== 0"
                                    :primaryValue="restaurant.total.orders_per_hour_avg"
                                    :compareValue="findRestaurant(compareLoad, restaurant.id).total.orders_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{restaurant.total.guests_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="findRestaurant(compareLoad, restaurant.id) && findRestaurant(compareLoad, restaurant.id).total.guests_per_hour_avg !== 0"
                                    :primaryValue="restaurant.total.guests_per_hour_avg"
                                    :compareValue="findRestaurant(compareLoad, restaurant.id).total.guests_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <span class="load-total__table-value">
                                    {{restaurant.total.dish_per_hour_avg | floatFormat}}
                                </span>
                                <difference class="load-total__table-value"
                                    v-if="findRestaurant(compareLoad, restaurant.id) && findRestaurant(compareLoad, restaurant.id).total.dish_per_hour_avg !== 0"
                                    :primaryValue="restaurant.total.dish_per_hour_avg"
                                    :compareValue="findRestaurant(compareLoad, restaurant.id).total.dish_per_hour_avg"
                                    mode="percentage"
                                    filter="percentFloatFormat"
                                />
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <template v-if="restaurant.total.hasOwnProperty('couriers_per_hour_avg')">
                                    <span class="load-total__table-value">
                                        {{restaurant.total.couriers_per_hour_avg | floatFormat}}
                                    </span>
                                    <difference class="load-total__table-value"
                                        v-if="findRestaurant(compareLoad, restaurant.id) && findRestaurant(compareLoad, restaurant.id).total.hasOwnProperty('couriers_per_hour_avg') && findRestaurant(compareLoad, restaurant.id).total.couriers_per_hour_avg !== 0"
                                        :primaryValue="restaurant.total.couriers_per_hour_avg"
                                        :compareValue="findRestaurant(compareLoad, restaurant.id).total.couriers_per_hour_avg"
                                        mode="percentage"
                                        filter="percentFloatFormat"
                                    />
                                </template>
                                <span v-else class="load-total__table-value">нет<br> данных</span>
                            </td>
                        </tr>
                        <tr class="load-total__table-row load-total__table-row_body"
                            v-if="findRestaurant(compareLoad, restaurant.id)"
                        >
                            <th class="load-total__table-cell load-total__table-cell_body" scope="row">
                                {{compareDaterange | daterangeText}}
                            </th>

                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{findRestaurant(compareLoad, restaurant.id).delivery.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{findRestaurant(compareLoad, restaurant.id).delivery.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{findRestaurant(compareLoad, restaurant.id).delivery.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <template v-if="findRestaurant(compareLoad, restaurant.id).delivery.hasOwnProperty('couriers_per_hour_avg')">
                                    {{findRestaurant(compareLoad, restaurant.id).delivery.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет<br> данных</template>
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{findRestaurant(compareLoad, restaurant.id).hall.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{findRestaurant(compareLoad, restaurant.id).hall.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{findRestaurant(compareLoad, restaurant.id).hall.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <template v-if="findRestaurant(compareLoad, restaurant.id).hall.hasOwnProperty('couriers_per_hour_avg')">
                                    {{findRestaurant(compareLoad, restaurant.id).hall.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет<br> данных</template>
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{findRestaurant(compareLoad, restaurant.id).take.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{findRestaurant(compareLoad, restaurant.id).take.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{findRestaurant(compareLoad, restaurant.id).take.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <template v-if="findRestaurant(compareLoad, restaurant.id).take.hasOwnProperty('couriers_per_hour_avg')">
                                    {{findRestaurant(compareLoad, restaurant.id).take.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет<br> данных</template>
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{findRestaurant(compareLoad, restaurant.id).pickup.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{findRestaurant(compareLoad, restaurant.id).pickup.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{findRestaurant(compareLoad, restaurant.id).pickup.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <template v-if="findRestaurant(compareLoad, restaurant.id).pickup.hasOwnProperty('couriers_per_hour_avg')">
                                    {{findRestaurant(compareLoad, restaurant.id).pickup.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет<br> данных</template>
                            </td>

                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{findRestaurant(compareLoad, restaurant.id).total.orders_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{findRestaurant(compareLoad, restaurant.id).total.guests_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                {{findRestaurant(compareLoad, restaurant.id).total.dish_per_hour_avg | floatFormat}}
                            </td>
                            <td class="load-total__table-cell load-total__table-cell_body load-total__table-cell_tar">
                                <template v-if="findRestaurant(compareLoad, restaurant.id).total.hasOwnProperty('couriers_per_hour_avg')">
                                    {{findRestaurant(compareLoad, restaurant.id).total.couriers_per_hour_avg | floatFormat}}
                                </template>
                                <template v-else>нет<br> данных</template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapMutations } from "vuex";
    import { daterangeText } from "@/helpers/daterange";
    import { compareStrings, compareNumbers } from "@/helpers/compare";
    import Difference from "@/components/Difference";

    export default{
        name: "LoadTotal",
        components: {
            Difference
        },
        props: {
            status: {
                type: String,
                required: true
            },
            reportDaterange: {
                type: Object,
                required: true
            },
            compareDaterange: {
                required: true
            },
            modes: {
                type: Array,
                required: true
            },
            modeId: {
                type: String,
                required: true
            },
            reportLoad: {
                required: true
            },
            compareLoad: {
                required: true
            },
            isReportTotalLinkVisible: {
                type: Boolean,
                required: true
            },
            isShowRestaurantsFilter: {
                type: Boolean,
                required: true
            },
            userRestaurants: {
                required: true
            }
        },
        data() {
            return {
                sorting: {
                    group: "total",
                    field: "orders_per_hour_avg",
                    direction: "DESC"
                }
            };
        },
        computed: {
            reportRestaurants() {
                return this.reportLoad?.restaurants?.sort((restaurant1, restaurant2) => {
                    if (this.sorting.group === "common" && this.sorting.field === "title") {
                        return compareStrings(
                            restaurant1.title,
                            restaurant2.title,
                            this.sorting.direction
                        );
                    }

                    return compareNumbers(
                        restaurant1[this.sorting.group][this.sorting.field],
                        restaurant2[this.sorting.group][this.sorting.field],
                        this.sorting.direction
                    );
                });
            },
            reportAverage() {
                if (this.userRestaurants?.ids?.length > 1) {
                    return {
                        delivery: {
                            orders_per_hour_avg: this.reportLoad.total.delivery.orders_per_hour_avg / this.reportLoad.restaurants.length,
                            guests_per_hour_avg: this.reportLoad.total.delivery.guests_per_hour_avg / this.reportLoad.restaurants.length,
                            dish_per_hour_avg: this.reportLoad.total.delivery.dish_per_hour_avg / this.reportLoad.restaurants.length,
                            couriers_per_hour_avg: this.reportLoad.total.delivery.couriers_per_hour_avg / this.reportLoad.restaurants.length
                        },
                        hall: {
                            orders_per_hour_avg: this.reportLoad.total.hall.orders_per_hour_avg / this.reportLoad.restaurants.length,
                            guests_per_hour_avg: this.reportLoad.total.hall.guests_per_hour_avg / this.reportLoad.restaurants.length,
                            dish_per_hour_avg: this.reportLoad.total.hall.dish_per_hour_avg / this.reportLoad.restaurants.length,
                            couriers_per_hour_avg: this.reportLoad.total.hall.couriers_per_hour_avg / this.reportLoad.restaurants.length
                        },
                        take: {
                            orders_per_hour_avg: this.reportLoad.total.take.orders_per_hour_avg / this.reportLoad.restaurants.length,
                            guests_per_hour_avg: this.reportLoad.total.take.guests_per_hour_avg / this.reportLoad.restaurants.length,
                            dish_per_hour_avg: this.reportLoad.total.take.dish_per_hour_avg / this.reportLoad.restaurants.length,
                            couriers_per_hour_avg: this.reportLoad.total.take.couriers_per_hour_avg / this.reportLoad.restaurants.length
                        },
                        pickup: {
                            orders_per_hour_avg: this.reportLoad.total.pickup.orders_per_hour_avg / this.reportLoad.restaurants.length,
                            guests_per_hour_avg: this.reportLoad.total.pickup.guests_per_hour_avg / this.reportLoad.restaurants.length,
                            dish_per_hour_avg: this.reportLoad.total.pickup.dish_per_hour_avg / this.reportLoad.restaurants.length,
                            couriers_per_hour_avg: this.reportLoad.total.pickup.couriers_per_hour_avg / this.reportLoad.restaurants.length
                        },
                        total: {
                            orders_per_hour_avg: this.reportLoad.total.total.orders_per_hour_avg / this.reportLoad.restaurants.length,
                            guests_per_hour_avg: this.reportLoad.total.total.guests_per_hour_avg / this.reportLoad.restaurants.length,
                            dish_per_hour_avg: this.reportLoad.total.total.dish_per_hour_avg / this.reportLoad.restaurants.length,
                            couriers_per_hour_avg: this.reportLoad.total.total.couriers_per_hour_avg / this.reportLoad.restaurants.length
                        }
                    };
                }
            }
        },
        methods: {
            ...mapMutations([
                "toggleReportTotalLink",
                "toggleRestaurantsFilter"
            ]),
            findRestaurant(load, restaurantId) {
                return load?.restaurants?.find(({ id }) => id === restaurantId);
            },
            setSorting(group, field) {
                if (this.sorting.group === group && this.sorting.field === field) {
                    this.sorting.direction = this.sorting.direction === "ASC" ? "DESC" : "ASC";
                } else {
                    this.sorting.group = group;
                    this.sorting.field = field
                }
            },
        },
        filters: {
            daterangeText
        },
        created() {
            if (this.userRestaurants?.ids?.length === 1) {
                this.$router.push({
                    name: "LoadRestaurant",
                    params:{
                        id: this.userRestaurants?.ids[0]
                    }
                });
            } else if (this.isReportTotalLinkVisible) {
                this.toggleReportTotalLink(false);
                this.toggleRestaurantsFilter(true);
            }
        }
    }
</script>

<style lang="scss">
    .load-total__box {
        padding: 8px 16px 16px;
        overflow-x: auto;

        & + & {
            margin-top: 30px;
        }
    }
    .load-total__table {
        width: 100%;
    }
    .load-total__table-caption {
        padding: 8px 8px 0;
        font-size: 24px;
        font-weight: bold;
    }
    .load-total__table-column {
        &_title {
            width: 12%;
        }

        &_average {
            width: calc(88% / 15);
        }
    }
    .load-total__table-row {
        &_body {
            .load-total__table_striped &:nth-of-type(odd) {
                background-color: $background-gray-primary;
            }

            &:hover {
                background-color: $gray-form;
            }
        }

        &_pinned {
            background-color: $gray-form !important;
        }
    }
    .load-total__table-cell {
        font-size: 20px;

        &_text-small {
            font-size: 14px;
        }

        &_text-medium {
            font-size: 16px;
        }

        &_head {
            padding: 8px;
            white-space: nowrap;
        }

        &_body {
            padding: 16px 8px;
        }

        &_sortable {
            cursor: pointer;
        }

        &_title {
            font-weight: bold;
        }

        &_vat {
            vertical-align: top;
        }

        &_tar {
            text-align: right;
        }

        &_tac {
            text-align: center;
        }
    }
    .load-total__table-value {
        display: block;
    }
    .load-total__table-icon {
        width: 16px;
        height: 16px;
        vertical-align: middle;
    }
    .load-total__table-text {
        vertical-align: middle;
    }
</style>
